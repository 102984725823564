import React from 'react';
import ContentWrap from '../ContentWrap';
import * as styles from './index.module.less';

interface MoreButtonProps {
  children?: React.ReactNode;
  to?: string;
}

export function MoreButton({ children = '了解详情', to = '' }: MoreButtonProps) {
  return (
    <a href={to} className={styles.moreBtn}>
      {children}
    </a>
  );
}

interface IProps {
  title?: string | React.ReactNode;
  desc?: string | React.ReactNode;
  children?: React.ReactNode;
  img?: string;
  layout?: 'center';
  height?: number;
  bannerStyle?: React.CSSProperties;
}

export default function BannerWrap({
  children,
  title,
  desc,
  img = '',
  bannerStyle,
  layout,
  height,
}: IProps) {
  const centerBlock: React.CSSProperties =
    layout === 'center'
      ? {
          alignItems: 'center',
          textAlign: 'center',
        }
      : {};
  return (
    <div className={styles.banner} style={{ height }}>
      <div className={styles.bannerBg} style={{ backgroundImage: `url(${img})`, ...bannerStyle }} />
      <ContentWrap className={styles.bannerContent} style={centerBlock}>
        <div className={styles.bannerTitle}>{title}</div>
        <div className={styles.bannerDesc}>{desc}</div>
        {children}
      </ContentWrap>
    </div>
  );
}
