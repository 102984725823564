import React, { useState } from 'react';
import Banner from '../../assets/images/cooperate-banner.png';
import BannerWrap, { MoreButton } from '../../components/BannerWrap';
import SectionWrap from '../../components/SectionWrap';

import * as styles from './index.module.less';
import { StaticImage } from 'gatsby-plugin-image';
import ContentWrap from '../../components/ContentWrap';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { contactUsModalShow } from '../../components/ContactUsModal';

export default function CooperatePage() {
  const [phone, setPhone] = useState('');
  return (
    <Layout>
      <BannerWrap
        img={Banner}
        layout="center"
        title="土星云生态合作伙伴"
        desc="土星云以软件定义存储技术为核心竞争力的高新技术企业，在分布式存储等关键技术上拥有自主知识产权，针对各行业业务特性，打造个性化行业解决方案，提供一站式的产品与服务。">
        {/* <MoreButton /> */}
      </BannerWrap>
      <SectionWrap title="为什么加入土星云合作伙伴">
        <div className={styles.list}>
          <div className={styles.item}>
            <StaticImage width={700} src="../../assets/images/cooperate-item-1.png" alt="" />
            <div className={styles.itemTitle}>丰富的市场资源</div>
            <div className={styles.itemDesc}>
              联合市场推广，业务拓展激励，标杆案例宣传，独家品牌授权，高端峰会交流，多渠道助力合作伙伴快速拓展业务
            </div>
          </div>

          <div className={styles.item}>
            <StaticImage width={700} src="../../assets/images/cooperate-item-2.png" alt="" />
            <div className={styles.itemTitle}>专业的赋能培训</div>
            <div className={styles.itemDesc}>
              定制赋能课程，完善产品培训、服务培训，全面提升合作伙伴专业知识及业务能力
            </div>
          </div>

          <div className={styles.item}>
            <StaticImage width={700} src="../../assets/images/cooperate-item-3.png" alt="" />
            <div className={styles.itemTitle}>领先的云存储技术</div>
            <div className={styles.itemDesc}>
              土星云存储软件基于 IPFS
              分布式架构开发，服务器及其他硬件均基于军工技术研发，产品拥有完整的自主知识产权
            </div>
          </div>

          <div className={styles.item}>
            <StaticImage width={700} src="../../assets/images/cooperate-item-4.png" alt="" />
            <div className={styles.itemTitle}>开放的土星云生态</div>
            <div className={styles.itemDesc}>
              土星云开放的心态和资源，合作伙伴互联共赢，共建分布式存储新生态
            </div>
          </div>
        </div>
      </SectionWrap>

      <div className={styles.friendSubmitContainer}>
        <ContentWrap style={{ position: 'relative', zIndex: 1 }}>
          <div className={styles.friendTitle}>合作伙伴申请</div>
          <div className={styles.friendDesc}>申请成为土星云的合作伙伴，我们会尽快与您联系!</div>
          <div className={styles.friendSteps}>
            <div>1. 申请加入</div>
            <div>2. 资质审核</div>
            <div>3. 业务沟通</div>
            <div>4. 展开合作</div>
          </div>
          <div className={styles.friendInput}>
            <input
              type="text"
              placeholder="请输入手机号"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <button
              onClick={() => {
                contactUsModalShow(phone.trim());
              }}>
              在线申请
            </button>
          </div>
        </ContentWrap>
      </div>
    </Layout>
  );
}

export function Head() {
  return <SEO title="生态合作" />;
}
