import React from 'react';
import ContentWrap from '../ContentWrap';
import * as styles from './index.module.less';

interface IProps {
  title?: string | React.ReactNode;
  desc?: string | React.ReactNode;
  children?: React.ReactNode;
  background?: string;
}

export default function SectionWrap({ children, title, desc, background }: IProps) {
  return (
    <div className={styles.container} style={{ background }}>
      <ContentWrap>
        {!!title && <h2 className={styles.title}>{title}</h2>}
        {desc && <h3 className={styles.desc}>{desc}</h3>}
        {children}
      </ContentWrap>
    </div>
  );
}
